import { Component, Inject, Input, OnInit, Optional } from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { DIALOG_DATA } from '../../../../global.variable';
import { ClientsService } from '../../../pages/pages/clients/clients.service';
import { LoadingSpinnerService } from '../../services/loading-spinner.service';
import { ToasterService } from '../../services/toaster.service';
import { AddEditClientComponent } from '../add-edit-client/add-edit-client.component';
import { DialogRef } from '../modal-dialogue/model-dialogue.ref';
import { ModelDialogueService } from '../modal-dialogue/model-dialogue.service';

@Component({
  selector: 'gtapp-add-edit-site',
  templateUrl: './add-edit-site.component.html',
  styleUrl: './add-edit-site.component.scss',
})
export class AddEditSiteComponent implements OnInit {
  @Input() detailData: any;

  @Input() editSite: any;

  // edit site name/address toggle
  @Input() editSiteName: boolean = true;

  siteForm: UntypedFormGroup = new UntypedFormGroup({
    addressLookup: new UntypedFormControl(''),
    company: new UntypedFormControl(null, Validators.required),
    company_name: new UntypedFormControl(null, Validators.required),
    address1: new UntypedFormControl('', [Validators.required]),
    address2: new UntypedFormControl('', [Validators.required]),
    address3: new UntypedFormControl(''),
    city_name: new UntypedFormControl('', [Validators.required]),
    postcode: new UntypedFormControl(
      '',
      Validators.compose([Validators.required, Validators.max(99999999)])
    ),
    longitude: new UntypedFormControl('', [Validators.required]),
    latitude: new UntypedFormControl('', [Validators.required]),
    country_name: new UntypedFormControl('Australia'),
    state_code: new UntypedFormControl(''),
    is_new_ui: new UntypedFormControl(true),
  });

  clientDataList: any;

  dialogref: any;

  initialDataList: any = {};

  addSiteFormData: any = {
    client: {
      name: 'Client',
      value: '',
      edit: false,
      data: null,
      tempValue: '',
    },
  };
  blurCalledDict: any = {};

  constructor(
    @Optional() protected dialogRef: DialogRef,
    private clientService: ClientsService,
    private spinnerService: LoadingSpinnerService,
    private dialogService: ModelDialogueService,
    @Inject(DIALOG_DATA) public dialogueData: any,
    private toasterService: ToasterService
  ) {}

  ngOnInit(): void {
    if (this.dialogueData) {
      this.detailData = this.dialogueData?.detailData;
      this.editSite = this.dialogueData?.editSite;
      this.editSiteName = this.dialogueData?.editSiteName;
      if (this.editSiteName) this.shiftFocus('siteInputField');
    }

    this.onClientSearch('');
    this.formatSiteData();
  }
  onBlurEventTrigger(field: string, inputId: any) {
    setTimeout(() => {
      const inputElement = document.getElementById(inputId) as HTMLElement;
      if (document.activeElement !== inputElement)
        // adding a timeout and checking the input field is still on focus so that close icon will work
        this.blurCalledDict[field] = true;
    }, 200);
  }
  editField(field: string) {
    if (field == 'client') {
      this.addSiteFormData.client = {
        name: 'Client',
        value: '',
        edit: false,
        data: null,
        tempValue: '',
      };
      this.shiftFocus('clientId');
    } else if (field == 'site') {
      this.blurCalledDict[field] = false;
      this.siteForm.controls['company_name'].setValue(null);
      this.shiftFocus('siteInputField');
    }

    this.updateInitialDataList(field);
  }
  updateInitialDataList(field: any) {
    setTimeout(() => {
      if (field === 'client') {
        this.clientDataList = JSON.parse(
          JSON.stringify(this.initialDataList?.[field])
        );
      }
    }, 200);
  }

  formatSiteData() {
    this.siteForm.patchValue(this.detailData);
    this.siteForm.controls['address1'].setValue(
      this.detailData?.address?.address1
    );
    this.siteForm.controls['address2'].setValue(
      this.detailData?.address?.address2
    );
    this.siteForm.controls['address3'].setValue(
      this.detailData?.address?.address3
    );
    this.siteForm.controls['city_name'].setValue(
      this.detailData?.address?.city_name
    );
    this.siteForm.controls['country_name'].setValue(
      this.detailData?.address?.country_name
    );
    this.siteForm.controls['postcode'].setValue(
      this.detailData?.address?.postcode
    );
    this.siteForm.controls['state_code'].setValue(
      this.detailData?.address?.state_code
    );
    this.siteForm.controls['latitude'].setValue(
      this.detailData?.address?.latitude
    );
    this.siteForm.controls['longitude'].setValue(
      this.detailData?.address?.longitude
    );
    this.siteForm.controls['company_name'].enable();
    this.siteForm.controls['is_new_ui'].setValue(true);
    if (this.detailData?.company) {
      this.addSiteFormData.client.tempValue = this.detailData?.clientName;
    }
  }
  onCloseDialogue(data: any = 'close') {
    this.dialogRef.close(data);
  }

  updateData() {
    this.spinnerService.show();
    if (this.siteForm.valid) {
      var requestData = { data: this.siteForm.getRawValue() };

      if (this.detailData?.id) {
        this.spinnerService.show();
        this.clientService
          .updateSiteById(requestData, this.detailData?.id)
          .then((response: any) => {
            if (response['status'] == 'success') {
              this.toasterService.setMessage({
                successMessage: response['message'],
                errorMessage: '',
              });

              this.onCloseDialogue(response['data']);
            } else {
              this.toasterService.setMessage({
                successMessage: '',
                errorMessage: response['message'],
              });
            }
            this.spinnerService.hide();
          });
      } else {
        this.spinnerService.show();
        this.clientService
          .addSiteInClient(requestData)
          .then((response: any) => {
            if (response['status'] == 'success') {
              this.toasterService.setMessage({
                successMessage: response['message'],
                errorMessage: '',
              });

              this.onCloseDialogue(response['data']);
            } else {
              this.toasterService.setMessage({
                successMessage: '',
                errorMessage: response['message'],
              });
            }
            this.spinnerService.hide();
          });
      }
    } else {
      this.toasterService.setMessage({
        successMessage: '',
        errorMessage: 'Please fil the required fields',
      });
    }
  }

  shiftFocus(elementId: string) {
    setTimeout(() => {
      var element = <HTMLInputElement>document.getElementById(elementId);
      element?.focus();
    });
  }

  onClientSearch(event: any) {
    this.addSiteFormData.client.tempValue = event ? event.target.value : '';
    this.clientService
      .searchClients(this.addSiteFormData.client.tempValue)
      .subscribe((res: any) => {
        if (res['status'] == 'success') {
          this.clientDataList = res['data'];
          if (!event) {
            this.initialDataList.client = JSON.parse(JSON.stringify(res?.data));
          }
        }
      });
  }
  onClientSelect(data: any) {
    if (data.key === 0) {
      this.openAddCustomerForm();
    }
    if (data?.id) {
      this.siteForm.controls['company'].setValue(data?.id);
      this.addSiteFormData.client.value = data?.company_name;
      this.addSiteFormData.client.data = data;
    }
  }
  openAddCustomerForm() {
    this.dialogref = this.dialogService.open(AddEditClientComponent, {
      data: {
        companyData: {
          company_name: this.addSiteFormData.client.tempValue,
          address: this.siteForm?.value,
        },
      },
    });

    this.dialogref.afterClosed().subscribe((value: any) => {
      if (value !== false) {
        this.onClientSelect(value?.data);
      }
    });
  }
}
