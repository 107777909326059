import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  browserGpsInfo,
  checkScanDistanceGreater,
} from '../../../../global.variable';
import { AppService } from '../../../app.service';
import { userUpdateTable } from '../../../pages/pages/tableDetails';
import { DataCheckService } from '../../services/data-check.service';
import { ImagePreviewComponent } from '../image-preview/image-preview.component';
import { ModelDialogueService } from '../modal-dialogue/model-dialogue.service';

@Component({
  selector: 'gtapp-selected-event-detail',
  templateUrl: './selected-event-detail.component.html',
  styleUrl: './selected-event-detail.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectedEventDetailComponent implements OnInit, OnChanges {
  @Input() selectedEvent: any;
  @Input() selectedEventFrom: string = 'job';
  @Input() extraCheck: boolean = false;
  @Input() userEventDetailsTableView: any;

  @Output() editUserComment = new EventEmitter();
  @Output() goBack = new EventEmitter();

  browserGpsInfo: any = browserGpsInfo;
  canEditComment: boolean = false;
  lastCommentEditTime: any;

  subscriberUserId: any;
  isAdmin: boolean = false;
  isDispatchUser: boolean = false;
  scanDistanceInfo: any = {};
  constructor(
    private dataCheckService: DataCheckService,
    private appService: AppService,
    private dialogService: ModelDialogueService
  ) {}
  ngOnInit(): void {
    this.isAdmin = this.dataCheckService.isUserAdmin();
    this.isDispatchUser = this.dataCheckService.isDispatchUser();
    let userData = this.appService.getUserData();

    this.subscriberUserId = userData?.subscriber?.subscriber_user_id;
    this.fetchResults();

    if (!this.userEventDetailsTableView) {
      this.userEventDetailsTableView = userUpdateTable.desktopTable;
    }
    window.scrollTo(0, 0);
    this.appService.initializeBootstrapTooltips();
  }
  ngOnChanges(changes: SimpleChanges): void {
    this.fetchResults();
  }
  fetchResults() {
    this.scanDistanceInfo = checkScanDistanceGreater(this.selectedEvent);
    this.selectedEvent.checkpoint_details.scanDistanceInfo =
      this.scanDistanceInfo;
    this.canEditComment = this.canEditCommentCheck();

    if (
      this.selectedEvent?.history_changes?.length &&
      (this.subscriberUserId == this.selectedEvent?.subs_user_id ||
        this.isAdmin ||
        this.isDispatchUser)
    ) {
      let sortedComments = this.selectedEvent?.history_changes?.sort(
        (a: any, b: any) =>
          new Date(a?.updated_at).getTime() - new Date(b?.updated_at).getTime()
      );
      this.lastCommentEditTime = sortedComments[sortedComments?.length - 1];
    }
    if (
      this.selectedEvent?.checkpoint_details?.id &&
      [0, 6].includes(this.selectedEvent?.event_action) &&
      this.selectedEvent?.event_type?.toLowerCase() === 'patrol update'
    ) {
      this.selectedEvent.gps.gpsToolTip = 'Scan Location';
    }
  }

  editComment(selectedEvent: any) {
    this.editUserComment.emit(selectedEvent);
  }
  hideDetails() {
    this.goBack.emit();
  }

  canEditCommentCheck() {
    switch (this.selectedEventFrom) {
      case 'job':
        return (
          this.subscriberUserId == this.selectedEvent?.subs_user_id &&
          [7, 8, 19].includes(this.selectedEvent?.event_action) &&
          this.extraCheck
        );
      case 'patrol':
        return (
          this.subscriberUserId == this.selectedEvent?.subs_user_id &&
          this.selectedEvent?.company_id &&
          [1, 2, 7].includes(this.selectedEvent?.event_action) &&
          new Date().getTime() -
            new Date(this.selectedEvent?.updated_at).getTime() <
            72 * 3600 * 1000 &&
          this.extraCheck
        );
      case 'user_update_history':
        return (
          this.subscriberUserId == this.selectedEvent?.subs_user_id &&
          this.selectedEvent?.company_id &&
          this.selectedEvent?.event_type === 'Patrol Update' &&
          [1, 2, 7].includes(this.selectedEvent?.event_action) &&
          new Date().getTime() -
            new Date(this.selectedEvent?.updated_at).getTime() <
            72 * 3600 * 1000 &&
          this.extraCheck
        );
    }
    return false;
  }

  getLastCommentEditedDateTime(history: any) {
    let sortedComments = history?.sort(
      (a: any, b: any) =>
        new Date(a?.updated_at).getTime() - new Date(b?.updated_at).getTime()
    );
    return sortedComments[sortedComments?.length - 1];
  }
  onOpenFile(file: any) {
    this.dialogService.open(ImagePreviewComponent, {
      data: { imageSrc: file?.file, timeStamp: file.updated_at },
    });
  }
}
