import { OverlayRef } from '@angular/cdk/overlay';
import { Observable, Subject } from 'rxjs';

export class DialogRef {
  private afterClosedSubject = new Subject<any>();

  constructor(private overlayRef: OverlayRef) {}

  public close(result?: any) {
    this.overlayRef.dispose();
    this.afterClosedSubject.next(result);
    this.afterClosedSubject.complete();

    if (!document.querySelector('.dialog-box')) {
      // if condition to avoid nested dialoges
      // adding scroll back by removing the class when the dialog box is closed..
      document.documentElement.classList.remove('cdk-global-scrollblock');
    }
  }

  public afterClosed(): Observable<any> {
    return this.afterClosedSubject.asObservable();
  }
}
