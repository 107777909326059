<div class="card dialog-box">
  <div class="card-header" *ngIf="title">
    {{ title }}
    <span class="float-end" (click)="onDismiss()"
      ><i class="fa-solid fa-xmark"></i
    ></span>
  </div>
  <div>
    <div *ngIf="message">
      <div class="alert" [ngStyle]="messageStyle">{{ message }}</div>
    </div>
    <!-- TODO: Add email validation -->
    <div *ngIf="body">
      <div class="form-floating">
        <input
          type="email"
          [(ngModel)]="bodyData"
          class="form-control"
          placeholder="New Email Address"
          autocorrect="off"
          autocapitalize="off"
          #emailInput="ngModel"
          name="bodyData"
          required
          email
        />
        <label>New Email</label>
      </div>
      <div
        *ngIf="emailInput.invalid && (emailInput.dirty || emailInput.touched)"
        class="text-danger"
      >
        Please enter a valid email address.
      </div>
      <div class="row row-cols-2 mt-3">
        <div class="col">
          <button
            (click)="onDismiss()"
            class="btn btn-lg btn-secondary w-100"
            type="button"
          >
            Cancel
          </button>
        </div>
        <div class="col">
          <button
            (click)="onConfirm($event)"
            class="btn btn-lg btn-primary w-100"
            type="button"
            [disabled]="emailInput.invalid"
          >
            Confirm
          </button>
        </div>
      </div>
    </div>

    <div *ngIf="confirmation">
      <div class="text-center">
        To confirm, type {{ randomNUmber }} in the field:
      </div>
      <input
        type="tel"
        placeholder="{{ randomNUmber }}"
        [(ngModel)]="confirmNumber"
        class="form-control"
      />
    </div>
    <div class="row row-cols-2 mt-2" *ngIf="!body">
      <div class="col">
        <button
          (click)="onDismiss()"
          class="btn btn-lg btn-secondary w-100"
          type="button"
        >
          Cancel
        </button>
      </div>
      <div class="col">
        <button
          (click)="onConfirm(true)"
          class="btn btn-lg btn-primary w-100"
          type="button"
        >
          Confirm
        </button>
      </div>
    </div>
  </div>
</div>
