<div class="card dialog-box">
  <div class="card-header">
    {{ updateView ? "Edit Response Type" : "Add New Response Type" }}
    <span class="float-end" (click)="onCloseDialogue('close')">
      <i class="fa-solid fa-xmark"></i>
    </span>
  </div>

  <form [formGroup]="responseForm">
    <div class="form-floating mb-3">
      <input
        class="form-control"
        type="text"
        id="response_name"
        formControlName="name"
        placeholder="Response Name"
        autofocus
        (keyup.enter)="responseForm.valid ? updateData() : null"
      />
      <label for="response_name">Response Name</label>
    </div>

    <div class="row">
      <div class="col">
        <button
          type="button"
          class="btn btn-lg btn-dark w-100"
          (click)="onCloseDialogue('close')"
        >
          Cancel
        </button>
      </div>
      <div class="col">
        <button
          type="button"
          class="btn btn-lg btn-primary w-100"
          (click)="updateData()"
          [disabled]="!responseForm.valid"
        >
          Submit
        </button>
      </div>
    </div>
  </form>
</div>
