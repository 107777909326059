<div class="dialog-box card">
  <ng-container *ngIf="selectedPagePrompts?.length">
    <div *ngFor="let field of selectedPagePrompts" class="details-card">
      <dl>
        <ng-template
          *ngTemplateOutlet="formTemplate; context: { value: field }"
        ></ng-template>
      </dl>
    </div>
  </ng-container>
  <ng-container>
    <span class="text-danger mb-2">Fields marked * cannot be empty </span>
    <div [ngClass]="page > 0 ? 'row row-cols-3' : 'row row-cols-2'">
      <div class="col" *ngIf="page > 0">
        <button
          [disabled]="page === 0"
          (click)="changePagination(false)"
          class="btn btn-dark w-100 btn-lg"
        >
          Back
        </button>
      </div>
      <div class="col">
        <button class="btn btn-light btn-lg w-100" (click)="closeDalog(false)">
          Cancel
        </button>
      </div>
      <div class="col" *ngIf="preview">
        <button
          class="btn btn-primary w-100 btn-lg"
          [disabled]="
            page + 1 < promptData?.length ? validatepage() : valdiateForm()
          "
          (click)="
            page + 1 < promptData?.length ? changePagination() : closeDalog()
          "
        >
          {{ page + 1 < promptData?.length ? "Next" : "OK" }}
        </button>
      </div>
      <div class="col" *ngIf="!preview">
        <button
          class="btn btn-primary w-100 btn-lg"
          [disabled]="
            page + 1 < promptData?.length ? validatepage() : valdiateForm()
          "
          (click)="
            page + 1 < promptData?.length ? changePagination() : savePromtData()
          "
        >
          {{ page + 1 < promptData?.length ? "Next" : "OK" }}
        </button>
      </div>
    </div>
  </ng-container>
</div>
<ng-template let-field="value" #formTemplate>
  <ng-container *ngIf="field.type === 'output'">
    <div class="field-output-description" *ngIf="field.description">
      {{ field.description }}
      <span class="text-danger" *ngIf="field?.required">*</span>
    </div>
  </ng-container>
  <ng-container *ngIf="field.type === 'input'">
    <div [ngSwitch]="field.field">
      <div class="field-input-description" *ngIf="field.description">
        {{ field.description }}
        <span class="text-danger" *ngIf="field?.required">*</span>
      </div>

      <ng-container *ngSwitchCase="'text'">
        <textarea
          rows="1"
          class="form-control"
          [required]="field?.required"
          [(ngModel)]="field.value"
        ></textarea>
      </ng-container>

      <ng-container *ngSwitchCase="'checkbox'">
        <div *ngFor="let opt of field.options">
          <div
            class="cursor-pointer form-check m-2"
            (click)="selectCheckBox(field, opt.key)"
          >
            <input
              class="form-check-input"
              type="checkbox"
              [checked]="field.value.includes(opt.key)"
            />
            {{ opt.key }}
          </div>
        </div>
        <div *ngIf="field.allowedSelections > 1" class="small-font float-end">
          Choose up to {{ field.allowedSelections }}
        </div>
      </ng-container>
    </div>
    <ng-container *ngIf="getSubOptions(field)">
      <div *ngFor="let subField of getSubOptions(field)">
        <ng-template
          *ngTemplateOutlet="formTemplate; context: { value: subField }"
        ></ng-template>
      </div>
    </ng-container>
  </ng-container>
</ng-template>
