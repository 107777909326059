<div class="card dialog-box">
  <div class="card-header">
    {{ updateView ? "Edit Service Type Type" : "Add New Service Type Type" }}
    <span class="float-end" (click)="onCloseDialogue('close')">
      <i class="fa-solid fa-xmark"></i>
    </span>
  </div>

  <form [formGroup]="serviceTypeForm">
    <div class="form-floating mb-3">
      <input
        class="form-control"
        type="text"
        id="service_type_name"
        formControlName="name"
        placeholder="Service Type Name"
        autocomplete="off"
        autofocus
        (keyup.enter)="serviceTypeForm.valid ? updateData() : null"
      />
      <label for="service_type_name">Service Type Name</label>
    </div>

    <div class="row">
      <div class="col">
        <button
          type="button"
          class="btn btn-lg btn-dark w-100"
          (click)="onCloseDialogue('close')"
        >
          Cancel
        </button>
      </div>
      <div class="col">
        <button
          type="button"
          class="btn btn-lg btn-primary w-100"
          (click)="updateData()"
          [disabled]="!serviceTypeForm.valid"
        >
          Submit
        </button>
      </div>
    </div>
  </form>
</div>
