import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  Input,
  OnInit,
  Optional,
} from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { DIALOG_DATA } from '../../../../global.variable';
import { ClientsService } from '../../../pages/pages/clients/clients.service';
import { LoadingSpinnerService } from '../../services/loading-spinner.service';
import { ToasterService } from '../../services/toaster.service';
import { DialogRef } from '../modal-dialogue/model-dialogue.ref';

@Component({
  selector: 'gtapp-add-edit-service-type',
  templateUrl: './add-edit-service-type.component.html',
  styleUrl: './add-edit-service-type.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddEditServiceTypeComponent implements OnInit {
  @Input() updateView: any;
  @Input() serviceTypeData: any;
  @Input() name: any;
  serviceTypeForm: UntypedFormGroup = new UntypedFormGroup({
    name: new UntypedFormControl('', [Validators.required]),
  });
  constructor(
    private clientService: ClientsService,
    private spinnerService: LoadingSpinnerService,
    @Optional()
    protected dialogRef: DialogRef,
    @Inject(DIALOG_DATA) public dialogueData: any,

    private toasterService: ToasterService
  ) {}

  ngOnInit(): void {
    this.updateView = this.dialogueData?.updateView;
    this.name = this.dialogueData?.name;
    this.serviceTypeData = this.dialogueData?.responseData;
    if (this.updateView === false) {
      this.serviceTypeForm.reset();
      if (this.name) {
        this.serviceTypeForm.controls['name'].setValue(this.name);
      }
    } else {
      this.serviceTypeForm.controls['name'].setValue(this.serviceTypeData.name);
    }
  }
  updateData() {
    if (this.serviceTypeForm.valid) {
      var requestData = this.serviceTypeForm.value;
      this.spinnerService.show();
      if (!this.updateView) {
        this.clientService
          .createServiceType(requestData)
          .then((response: any) => {
            if (response['status'] == 'success') {
              this.spinnerService.hide();

              this.onCloseDialogue(response);
              this.toasterService.setMessage({
                errorMessage: '',
                successMessage: response['message'],
              });
              this.serviceTypeForm.reset();
              this.updateView = false;
            } else {
              this.spinnerService.hide();
              this.toasterService.setMessage({
                errorMessage: response['message'],
                successMessage: '',
              });
            }
          });
      } else {
        requestData['id'] = this.serviceTypeData.id;
        this.clientService
          .updateServiceTypeDetailById(requestData)
          .then((response: any) => {
            if (response['status'] == 'success') {
              this.spinnerService.hide();

              this.toasterService.setMessage({
                successMessage: response['message'],
                errorMessage: '',
              });

              this.onCloseDialogue(response);

              this.updateView = false;
            } else {
              this.spinnerService.hide();
              this.toasterService.setMessage({
                errorMessage: response['message'],
                successMessage: '',
              });
            }
          });
      }
    }
  }
  onCloseDialogue(value?: any) {
    this.dialogRef.close(value);
  }
}
