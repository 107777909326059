import { ComponentType, Overlay } from '@angular/cdk/overlay';
import { ComponentPortal, TemplatePortal } from '@angular/cdk/portal';
import {
  Injectable,
  Injector,
  TemplateRef,
  Type,
  ViewContainerRef,
} from '@angular/core';
import { DIALOG_DATA } from '../../../../global.variable';
import { DialogRef } from './model-dialogue.ref';
export interface DialogConfig {
  data?: any;
}

@Injectable({
  providedIn: 'root',
})
export class ModelDialogueService {
  componentFactoryResolver: any;

  constructor(private overlay: Overlay, private injector: Injector) {}

  open<T>(
    componentOrTemplate: ComponentType<T> | TemplateRef<T> | any,
    config?: DialogConfig,
    viewContainerRef: ViewContainerRef | any = ''
  ): DialogRef {
    const positionStrategy = this.overlay
      .position()
      .global()
      .centerHorizontally()
      .centerVertically();
    const overlayRef = this.overlay.create({
      positionStrategy,
      hasBackdrop: true,
      backdropClass: 'overlay-backdrop',
      panelClass: 'overlay-panel',
    });
    const dialogRef = new DialogRef(overlayRef);
    const injector = Injector.create({
      parent: this.injector,
      providers: [
        { provide: DialogRef, useValue: dialogRef },
        { provide: DIALOG_DATA, useValue: config?.data },
      ],
    });
    // removing scroll at the html level; done to remove scroll when dialog box is open.
    document.documentElement.classList.add('cdk-global-scrollblock');

    // Attach component portal to the overlay
    if (this.isComponentType(componentOrTemplate)) {
      const componentPortal = new ComponentPortal(
        componentOrTemplate as Type<T>,
        null,
        injector
      );
      overlayRef.attach(componentPortal);
    } else {
      overlayRef.attach(
        new TemplatePortal(componentOrTemplate, viewContainerRef, {
          $implicit: config?.data,
          dialogRef: dialogRef,
        } as any)
      );
    }
    return dialogRef;
  }

  private isComponentType(value: any): value is ComponentType<any> {
    return value && value.prototype && value.prototype.ngOnInit;
  }
}
