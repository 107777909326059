<ng-container *ngIf="changableRadius">
  <ng-container
    *ngIf="['smallMapOtherJob', 'largeMapOtherJob'].includes(mapId)"
  >
    <div class="mb-2 p-1">
      Notify me if guard is detected outside of this area.
    </div>
  </ng-container>

  <div class="form-floating mb-2">
    <input
      class="form-control"
      nbInput
      type="tel"
      [min]="minKm"
      placeholder="
      Geofence Radius (metres)
    "
      [max]="maxKm"
      [(ngModel)]="nearestKmValue"
      (input)="changeGeoFenceRadius()"
      (blur)="changeGeoFenceRadius()"
      pattern="\d*"
    /><label for="inputAssignee"> Geofence Radius (metres)</label>
  </div>

  <input
    *ngIf="largeView"
    class="range-slider-input mb-2"
    type="range"
    style="width: 100%"
    [min]="minKm"
    [max]="maxKm"
    [(ngModel)]="nearestKmValue"
    pattern="\d*"
    placeholder="
    Geofence Radius (metres)
    "
    (ngModelChange)="onSliderChange()"
    (mouseup)="changeGeoFenceRadius()"
    (touchend)="changeGeoFenceRadius()"
  />
</ng-container>

<div
  [id]="mapId"
  class="map"
  style="position: relative; z-index: 0; max-height: 100%; max-width: 100%"
  [ngStyle]="
    mapId === 'checkpointEditmapid'
      ? { height: '40vh' }
      : mapId === 'alertMapId'
      ? { height: '30vh' }
      : {}
  "
></div>
<div class="small text-center text-body-tertiary">
  {{ latLon?.lat }}, {{ latLon?.lon }}
</div>
