<div class="card dialog-box">
  <div class="card-header">
    {{ incidentTypeData?.id ? "Edit Incident Type" : "Add New Incident Type" }}
    <span class="float-end" (click)="onCloseDialogue('close')">
      <i class="fa-solid fa-xmark"></i>
    </span>
  </div>

  <form [formGroup]="incidentTypeForm">
    <div class="input-group mb-3">
      <div class="form-floating">
        <input
          class="form-control"
          type="text"
          id="typeField"
          formControlName="name"
          placeholder="Incident Type"
          autocomplete="off"
          autofocus
          (keyup.enter)="incidentTypeForm.valid ? updateData() : null"
        />
        <label for="typeField">Incident Type</label>
      </div>

      <span
        class="input-group-text"
        *ngIf="incidentTypeForm.value.name"
        (click)="editField()"
      >
        <i class="fa-solid fa-xmark"></i>
      </span>
    </div>

    <div class="row">
      <div class="col">
        <button
          type="button"
          class="btn btn-lg btn-dark w-100"
          (click)="onCloseDialogue('close')"
        >
          Cancel
        </button>
      </div>
      <div class="col">
        <button
          type="button"
          class="btn btn-lg btn-primary w-100"
          (click)="updateData()"
          [disabled]="!incidentTypeForm.valid"
        >
          Submit
        </button>
      </div>
    </div>
  </form>
</div>
