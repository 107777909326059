<div class="row mt-2 back-btn-height">
  <div class="col-md-5 details-card">
    <div class="mt-2" *ngIf="selectedEvent?.detail">
      <div *ngIf="scanDistanceInfo?.scanDistance" class="text-danger my-2">
        Scanned outside configured checkpoint radius
      </div>
      <div>
        <strong>{{
          selectedEvent?.action !== "Scanned Qr"
            ? selectedEvent?.action
            : "Scanned QR Checkpoint"
        }}</strong>
        <span
          *ngIf="
            selectedEvent?.history_changes?.length &&
            (subscriberUserId == selectedEvent?.subs_user_id ||
              isAdmin ||
              isDispatchUser)
          "
        >
          | Edited [{{
            getLastCommentEditedDateTime(selectedEvent?.history_changes)
              ?.updated_at | date : "d MMM y HH:mm"
          }}]</span
        >
      </div>
      <div
        class="fs-6"
        (click)="canEditComment ? editComment(selectedEvent) : ''"
      >
        {{ selectedEvent?.detail }}
        <span *ngIf="selectedEvent?.eta"
          >[ETA: {{ selectedEvent?.eta | customDate : "d MMM y HH:mm" }} ]</span
        >
        <span class="float-end" *ngIf="canEditComment">
          <i class="fa-solid fa-pen-to-square"></i>
        </span>
      </div>

      <hr />
    </div>

    <div class="row row-cols-1 row-cols-xxl-2 mb-2">
      <ng-container *ngFor="let indColumn of userEventDetailsTableView">
        <ng-container
          *ngIf="
            (indColumn.nestedValue
              ? indColumn.nestedValue(selectedEvent)
              : selectedEvent[indColumn.name]
            )?.trim()
          "
        >
          <div>
            <dl>
              <dt class="text-uppercase">
                {{ indColumn?.header }}
              </dt>
              <ng-container *ngIf="indColumn?.datetimeObj == true">
                <dd>
                  {{
                    indColumn.nestedValue
                      ? (indColumn.nestedValue(selectedEvent)
                        | date
                          : (indColumn.dateTimeFormat
                              ? indColumn.dateTimeFormat
                              : "d MMM y HH:mm"))
                      : (selectedEvent[indColumn.name]
                        | date
                          : (indColumn.dateTimeFormat
                              ? indColumn.dateTimeFormat
                              : "d MMM y HH:mm"))
                  }}
                </dd></ng-container
              >
              <ng-container *ngIf="!indColumn?.datetimeObj">
                <dd>
                  {{
                    indColumn.nestedValue
                      ? indColumn.nestedValue(selectedEvent)
                      : selectedEvent[indColumn.name] == "null" ||
                        selectedEvent[indColumn.name] == "undefined"
                      ? "N/A"
                      : selectedEvent[indColumn.name]
                  }}
                </dd></ng-container
              >
            </dl>
          </div>
        </ng-container>
      </ng-container>
      <div *ngIf="selectedEvent?.checkpoint_details?.min_distance">
        <dl>
          <dt class="text-uppercase">Geofence Radius (metres)</dt>
          <dd>
            {{
              selectedEvent?.checkpoint_details?.min_distance | number : "1.0-0"
            }}
          </dd>
        </dl>
      </div>
    </div>

    <div
      *ngIf="selectedEvent?.recorded_at && !selectedEvent?.delayed_event"
      class="mb-2"
    >
      <dl>
        <dt class="text-danger fw-bold d-flex align-items-center">
          CAPTURED TIME
          <span
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            class="ms-2"
            [attr.title]="'This event was captured when the user was offline'"
          >
            <i class="fa-solid fa-circle-info"></i>
          </span>
        </dt>
        <dd>{{ selectedEvent?.recorded_at | date : "d MMM y HH:mm" }}</dd>
      </dl>
    </div>

    <ng-container *ngIf="selectedEvent?.prompts?.length">
      <div *ngFor="let item of selectedEvent?.prompts" class="my-3">
        <div class="fw-bold">
          {{ item?.prompt_data?.name ? item?.prompt_data?.name : "Prompt" }}
        </div>

        <div *ngFor="let dataItem of item?.data">
          <ng-template
            *ngTemplateOutlet="promptTemplate; context: { value: dataItem }"
          ></ng-template>
        </div>
      </div>
    </ng-container>
    <ng-container
      *ngIf="selectedEvent?.device_details && (isAdmin || isDispatchUser)"
    >
      <div class="row row-cols-1 row-cols-lg-2">
        <div *ngFor="let item of selectedEvent?.device_details | keyvalue">
          <dl *ngIf="item?.value">
            <dt class="text-uppercase">
              {{ item.key }}
            </dt>
            <dd class="text-break">{{ item.value }}</dd>
          </dl>
        </div>
        <div>
          <dl>
            <dt>GPS Accuracy</dt>
            <dd>
              {{ browserGpsInfo?.[selectedEvent?.gps?.source]?.description }}
            </dd>
          </dl>
        </div>
      </div>
    </ng-container>
  </div>

  <div class="col-md-7">
    <div
      [ngClass]="selectedEvent?.file ? 'row row-cols-md-2' : 'row row-cols-1'"
    >
      <div
        class="col-md-6 my-2"
        *ngIf="selectedEvent?.file"
        (click)="onOpenFile(selectedEvent)"
      >
        <img
          class="cursor-pointer mw-100 border border-secondary"
          src="{{ selectedEvent?.file }}"
          alt="unplash"
        />
      </div>
      <div
        [ngClass]="selectedEvent?.file ? 'col-md-6' : 'col'"
        *ngIf="isAdmin || isDispatchUser"
      >
        <gtapp-map-leaflet
          *ngIf="selectedEvent?.gps"
          [latLon]="selectedEvent?.gps"
          [scannedCheckpointDetails]="selectedEvent?.checkpoint_details"
          [mapId]="'event-location'"
        ></gtapp-map-leaflet>
      </div>
    </div>
  </div>
</div>
<div class="row mt-3 bottom-section" id="fixedBtn">
  <div class="px-3 pb-2">
    <button
      class="btn btn-dark d-block d-md-none w-100 btn-lg"
      (click)="hideDetails()"
    >
      Back
    </button>
  </div>
</div>

<!--looped prompt template-->
<ng-template let-dataItem="value" #promptTemplate>
  <div *ngIf="dataItem?.description">
    <dl>
      <dt class="text-uppercase">{{ dataItem?.description }}</dt>

      <dd>{{ dataItem | misc : "promptValue" }}</dd>
    </dl>
  </div>

  <div *ngFor="let option of dataItem?.options">
    <ng-template
      *ngTemplateOutlet="promptTemplate; context: { value: option }"
    ></ng-template>
  </div>
</ng-template>
