import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AppService } from '../../../app.service';
import { DeviceInfoService } from '../../services/device-info.service';
import { LoadingSpinnerService } from '../../services/loading-spinner.service';
import { ModelDialogueService } from '../modal-dialogue/model-dialogue.service';
import { SaveNewAddressComponent } from '../save-new-address/save-new-address.component';

@Component({
  selector: 'gtapp-address-lookup',
  templateUrl: './address-lookup.component.html',
  styleUrl: './address-lookup.component.scss',
})
export class AddressLookupComponent implements OnInit {
  @Input() addressForm: any;
  @Input() editSelectedAddress: boolean = false;

  @Input() reverseLookupGps: any;

  @Output() updateAddress = new EventEmitter();
  @Output() lookupNewAddress = new EventEmitter();

  invalidFields: any = [];
  addrLookupData: any = [];
  stateDataList: any = [];
  addressValue: string = '';

  constructor(
    private dialogService: ModelDialogueService,
    private appService: AppService,
    private spinnerService: LoadingSpinnerService,
    private deviceInfoService: DeviceInfoService
  ) {}

  ngOnInit(): void {
    this.getStates(1);
    if (!this.editSelectedAddress) {
      this.updateFullAddress(this.addressForm.value);
    }
  }
  getStates(countryId: any) {
    this.appService.getStates(+countryId).subscribe((response: any) => {
      if (response['status'] == 'success') {
        this.stateDataList = response['data'];
      }
    });
  }
  forceUppercaseConditionally(event: any) {
    this.addressForm.controls['city_name'].setValue(
      event.target.value.toUpperCase()
    );
  }

  onSearchAnotherAddress() {
    this.lookupNewAddress.emit(true);
    const addressFields = [
      'address1',
      'address2',
      'address3',
      'city_name',
      'postcode',
      'state_code',
      'latitude',
      'longitude',
    ];
    Object.keys(this.addressForm.controls).forEach((key) => {
      if (addressFields.includes(key)) {
        this.addressForm.get(key).reset();
      }
    });

    this.addressValue = '';
    this.editSelectedAddress = false;
    this.shiftFocus();
  }
  shiftFocus() {
    setTimeout(() => {
      var element = <HTMLInputElement>document.getElementById('addressLookup');
      if (element) {
        element?.focus();
      }
    }, 100);
  }
  addPlace() {
    const dialogRef = this.dialogService.open(SaveNewAddressComponent, {
      data: { showFrom: 'fullAddressRequired' },
    });
    dialogRef.afterClosed().subscribe((value: any) => {
      if (value && value != 'close') {
        this.addressSelected(value);
      }
    });
  }
  updateFullAddress(address: any) {
    if (
      address?.address1 &&
      address?.address2 &&
      address?.city_name &&
      address?.postcode &&
      address?.state_code
    ) {
      const address3 = address?.address3 ? `${address?.address3},` : '';
      const address1 = address?.address1 || '';
      const address2 = address?.address2 || '';
      const city_name = address?.city_name || '';
      const state_code = address?.state_code || '';
      const postcode = address?.postcode || '';
      this.addressValue =
        address?.full_address ||
        `${address3} ${address1} ${address2} ${city_name} ${state_code} ${postcode}`.trim();
    }
  }
  addressFormInValidCheck() {
    return (
      this.addressForm.controls['address1'].invalid ||
      this.addressForm.controls['address2'].invalid ||
      this.addressForm.controls['postcode'].invalid ||
      this.addressForm.controls['city_name'].invalid ||
      this.addressForm.controls['state_code'].invalid
    );
  }
  addressSelected(address: any) {
    if (address?.key === 0) {
      this.useCurrentLocation();
    } else if (address && typeof address === 'object') {
      let selectedAddress: any = {
        address1: address?.address1,
        address2: address?.address2,
        address3: address?.address3,
        city_name: address?.city_name,
        postcode: address?.postcode,
        state_code: address?.state_code,
        country_name: address?.country_name,
        latitude: address?.latitude,
        longitude: address?.longitude,
      };
      if (address.hasOwnProperty('properties')) {
        selectedAddress = {
          address1: address?.properties?.context?.address?.address_number,
          address2: address?.properties?.context?.street?.name?.toUpperCase(),
          address3: null,
          city_name:
            address?.properties?.context?.neighborhood?.name?.toUpperCase() ||
            address?.properties?.context?.locality?.name?.toUpperCase() ||
            address?.properties?.context?.place?.name?.toUpperCase(),
          state_code: this.getStateCode(
            address?.properties?.context?.region?.region_code
          ),
          longitude: address?.properties?.coordinates?.longitude,
          latitude: address?.properties?.coordinates?.latitude,
          postcode: address?.properties?.context?.postcode?.name,
        };
      }

      this.editSelectedAddress = false;

      this.addressForm.patchValue(selectedAddress);

      if (this.addressFormInValidCheck()) {
        this.editSelectedAddress = true;
      }
      this.updateAddress.emit(selectedAddress);

      this.updateFullAddress(this.addressForm.value);
    }
  }
  checkForFullAddress() {
    if (!this.addressFormInValidCheck()) {
      let selectedAddress: any = {
        address1: this.addressForm?.value?.address1,
        address2: this.addressForm?.value?.address2,
        address3: this.addressForm?.value?.address3,
        city_name: this.addressForm?.value?.city_name,
        postcode: this.addressForm?.value?.postcode,
        state_code: this.addressForm?.value?.state_code,
        country_name: this.addressForm?.value?.country_name,
        latitude: this.addressForm?.value?.latitude,
        longitude: this.addressForm?.value?.longitude,
      };
      this.addressSelected(selectedAddress);
    }
  }
  gtAddressDbLookup(searchStr: any) {
    this.appService.addressLookupShared(searchStr).subscribe((res: any) => {
      this.addrLookupData = res['data'];
    });
  }
  thridPartySearchAddress(searchStr: any) {
    this.appService
      .addressLookupShared(searchStr, { search_3rd_party: 1 })
      .subscribe((res: any) => {
        this.addrLookupData = res?.data;
      });
  }

  onAddressSearch(addressSearch: any) {
    if (addressSearch?.target?.value?.length > 4) {
      this.thridPartySearchAddress(addressSearch?.target?.value);
    } else if (addressSearch?.target?.value?.length > 2) {
      this.gtAddressDbLookup(addressSearch?.target?.value);
    }
  }

  getStateCode(stateCode: any) {
    let codeList = this.stateDataList?.filter(
      (state: any) => state?.code?.toLowerCase() === stateCode?.toLowerCase()
    );
    return codeList?.length == 1 ? stateCode?.toUpperCase() : null;
  }
  async useCurrentLocation() {
    this.spinnerService.show();
    this.reverseLookupGps = await this.deviceInfoService.getGpsCoordinates();
    this.getAddressFromGps(this.reverseLookupGps);
  }
  getAddressFromGps(gps: any) {
    this.spinnerService.show();
    this.appService
      .getReverseLocation(gps?.lat, gps?.lon)
      .subscribe((response: any) => {
        let addressStreet = response?.address?.Address.split(' ');
        let streetName = [
          ...addressStreet.filter(
            (item: any) => item != response?.address?.AddNum
          ),
        ].join(' ');
        const address = {
          address1: response?.address?.AddNum,
          address2: streetName?.toUpperCase(),
          address3: null,
          city_name: response?.address?.Neighborhood
            ? response?.address?.Neighborhood?.toUpperCase()
            : response?.address?.City?.toUpperCase(),
          state_code: this.getStateCode(response?.address?.RegionAbbr),
          longitude: gps?.lon,
          latitude: gps?.lat,
          postcode: response?.address?.Postal,
        };
        this.addressSelected(address);

        this.spinnerService.hide();
      });
  }
}
