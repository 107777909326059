<div class="card dialog-box">
  <div class="card-header">
    {{ updateView ? "Edit Client Details" : "Add New Client" }}
    <span class="float-end" (click)="onCloseDialogue()">
      <i class="fa-solid fa-xmark"></i>
    </span>
  </div>

  <form [formGroup]="clientForm" appFormEnterAction>
    <ng-container
      *ngIf="clientForm.value.company_name && blurCalledDict?.client"
    >
      <dl class="p-2">
        <div class="d-flex align-items-center">
          <div class="flex-grow-1">
            <dt>Client Name</dt>
            <dd>
              {{ clientForm.value.company_name }}
            </dd>
          </div>
          <div>
            <span (click)="editField()">
              <i
                class="fa-solid fa-pen-to-square md-font-size text-secondary"
              ></i
            ></span>
          </div>
        </div>
      </dl>
    </ng-container>

    <div
      class="input-group my-2"
      *ngIf="!blurCalledDict?.client || !clientForm.value.company_name"
    >
      <div class="form-floating">
        <input
          class="form-control"
          type="text"
          id="clientField"
          formControlName="company_name"
          autocomplete="off"
          placeholder="Company Name"
          maxlength="60"
          autofocus
          (blur)="
            clientForm.value.company_name
              ? onBlurEventTrigger('client', 'clientField')
              : null
          "
        />
        <label for="company_name">Client Name</label>
      </div>

      <span
        class="input-group-text"
        *ngIf="clientForm.value.company_name"
        (click)="editField()"
      >
        <i class="fa-solid fa-xmark"></i>
      </span>
    </div>

    <gtapp-address-lookup [addressForm]="clientForm"> </gtapp-address-lookup>
    <div class="row row-cols-2 mt-4">
      <div>
        <button
          type="button"
          class="btn btn-dark w-100 btn-lg"
          (click)="onCloseDialogue()"
        >
          Cancel
        </button>
      </div>
      <div>
        <button
          type="button"
          class="btn btn-primary w-100 btn-lg"
          (click)="updateData()"
          [disabled]="!clientForm.valid"
        >
          Submit
        </button>
      </div>
    </div>
  </form>
</div>
